<template>
  <div class="body-custom" v-loading="loading">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
              <i class="flaticon2-delivery-package text-primary"></i>
          </span>
          <h3 class="card-label"><b class="title-header">承認プロセス検索</b></h3>
        </div>
        <div>
          <ButtonSearchMobile></ButtonSearchMobile>
        </div>
      </div>
      <div class="card-body">
        <div id="search-form">
          <el-form
              ref="ruleForm"
              label-position="left"
              class="demo-ruleForm demo-form"
              @keyup.enter.native="onSearch"
          >
            <div class="row">
              <div class="col-md-8 col-sm-12 col-xs-6">
                <div class="row">
                  <div class="col-md-4 col-6">
                    <el-form-item label="承認プロセス名" prop="approval_name">
                      <el-input
                      name="approval_name"
                      autocomplete="on"
                      placeholder="承認プロセス名を入力"
                      type="text"
                      v-model="searchForm.approval_name"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-4 col-6">
                    <el-form-item label="承認段階" prop="approval_step">
                      <el-input
                      name="approval_step"
                      autocomplete="on"
                      placeholder="承認段階を入力"
                      type="text"
                      v-model="searchForm.approval_step"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-4 col-6">
                    <el-form-item label="利用権限" prop="role_name">
                      <el-select
                          multiple
                          style="width: 100%"
                          v-model="searchForm.role_name"
                      >
                        <el-option
                            v-for="item in optionRoleStatus"
                            :key="item.role_id"
                            :label="item.role_name"
                            :value="item.role_id"
                        >
                         </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-4 col-6">
                    <el-form-item label="ステータス" prop="approval_status">
                      <el-select
                          multiple
                          style="width: 100%"
                          v-model="searchForm.approval_status"
                      >
                        <el-option
                            v-for="item in optionApprovalStatus"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                         </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-12 text-right">
                    <div class="button-submit-mobile" style="padding-top: 0">
                      <el-button
                          @click="showFormSearch()"
                          @click.prevent="onSearch"
                          type="primary"
                      >{{ $t("search") }}</el-button
                      >
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-4 col-xs-6">
                <div class="action-form-search">
                  <button @click.prevent="onSearch" class="btn btn-primary mr-2">{{$t('search')}}</button>
                  <button
                          :disabled="listApproval.total <= 0"
                          @click.prevent="exportExcel" class="btn btn-primary mr-2">{{$t('export_csv')}}</button>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div id="data-table-list">
          <div class="header-table-mobile">
            <button @click="exportExcel"
              type="button" class="btn btn-primary mr-2"
              :disabled="listApproval.total <= 0"
              >{{$t('export_csv')}}</button>
          </div>
          <div class="row float-right pr-5">
            <my-pagination
                v-if="listApproval.total"
                :total-record="parseInt(listApproval.total)"
                :current-page="parseInt(listApproval.current_page)"
                :page-size-default="parseInt(listApproval.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
          <el-table
              v-if="listApproval.data && listApproval.total > 0"
              class="mb-5 table-search"
              :data="listApproval.data"
              style="width: 100%"
              @sort-change="sortApproval"
          >
            <el-table-column
                prop="approval_name"
                class="column-table-2"
                label="承認プロセス名"
                sortable="false"
                min-width="180"
            >
            <template slot-scope="scope">
                <span v-if="hasPermissonCheck('kengen_flg')">
                    <router-link :to="{ name: 'process-approval-edit', params: { processId:  scope.row.approval_id }}">{{ scope.row.approval_name}}</router-link>
                 </span>
                <span v-else>
                    <a class="text-primary cursor-pointer" @click="not_permisson_popup = true">{{ scope.row.approval_name}}</a>
                 </span>
              </template>
            </el-table-column>
            <el-table-column
                prop="approval_step"
                class="column-table"
                label="承認段階"
                sortable="false"
                min-width="150"
            >
            </el-table-column>
            <el-table-column
                prop="approval_status"
                class="column-table"
                label="ステータス"
                sortable="false"
                min-width="150"
            >
            <template slot-scope="scope">
                <span v-if="parseInt(scope.row.approval_status) === constants.SATUS_APPROVAL[0].value">{{constants.SATUS_APPROVAL[0].label}}</span>
                <span v-else-if="parseInt(scope.row.approval_status) === constants.SATUS_APPROVAL[1].value">{{constants.SATUS_APPROVAL[1].label}}</span>
            </template>
            </el-table-column>
            <el-table-column
                label="テナント名"
                min-width="200">
              <template slot-scope="scope">
                <span v-if="scope.row.tenant">
                  <a target="_blank" :href="scope.row.tenant.admin_url">
                    {{scope.row.tenant.tenant_name}}
                  </a>
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div v-else class="text-center no-list-data">{{$t('el.table.emptyText')}}</div>
          <div class="row float-right pr-5">
            <my-pagination
                v-if="listApproval.total"
                :total-record="parseInt(listApproval.total)"
                :current-page="parseInt(listApproval.current_page)"
                :page-size-default="parseInt(listApproval.per_page)"
                @handleCurrentChange="handleCurrentChange"
                @handleSizeChange="handleSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>
<script>
import mixinSearch from "@/mixins/searchMixin";
import * as constants from "@/core/config/constant";
import { mapActions, mapGetters } from "vuex";
import {
  GET_LIST_APPROVAL,
  EXPORT_APPROVAL_FILE_CSV,
  GET_LIST_ROLE_NAME,
} from "@/core/services/store/approval.module";
import ButtonSearchMobile from "@/view/components/ButtonSearchMobile";


export default {
  name: "list-approval",
  components: {ButtonSearchMobile},
  mixins: [mixinSearch],
  data() {
    return {
      searchForm: {
        approval_name: "",
        approval_step: "",
        approval_status: [],
        role_name: [],
      },
      optionApprovalStatus: [],
      optionRoleStatus: [],
      loading: false,
      constants: constants,
      not_permisson_popup:false
    };
  },
  computed: {
    ...mapGetters(["listApproval", "listRoleName"]),
    queryParam() {
      return this.$route.query
    },
  },
  watch: {
    async queryParam() {
      this.loading = true;
        if (Object.keys(this.queryParam).length === 0){
            this.resetDataSearch();
        }
      await this.getListApproval(this.queryParam);
      await this.getListRoleName(this.queryParam);
      this.getDataFromQuery();
      this.loading = false;
    },
  },
  async created() {
    if (this.hasPermissonCheck('kengen_flg')) {
      this.loading = true;
      this.getDataFromQuery();
      await this.getListApproval(this.queryParam);
      await this.getListRoleName(this.queryParam);
      this.optionApprovalStatus = constants.SATUS_APPROVAL;
      this.optionRoleStatus = this.listRoleName;
      this.loading = false;
    } else {
      this.not_permisson_popup = true;
    }
  },
  methods: {
    ...mapActions([GET_LIST_APPROVAL, EXPORT_APPROVAL_FILE_CSV, GET_LIST_ROLE_NAME]),
    getDataFromQuery() {
      this.searchForm.approval_name = this.queryParam.approval_name ? this.queryParam.approval_name : "";
      this.searchForm.approval_step = this.queryParam.approval_step ? this.queryParam.approval_step : "";
      var arrayApprovalStatus = (this.queryParam.approval_status && this.queryParam.approval_status.length ) ? this.queryParam.approval_status.split(',') : [];
      var arrayRoleName = (this.queryParam.role_name && this.queryParam.role_name.length) ? this.queryParam.role_name.split(',') : [];
      this.searchForm.role_name = arrayRoleName.map(item => parseInt(item));
      this.searchForm.approval_status = arrayApprovalStatus.map(item => parseInt(item));
    },
    onSearch() {
      this.getDataSearch(this.searchForm);
      this.search("list-approval");
    },
    sortApproval(sortProps) {
      this.sortColumn("list-approval",sortProps);
      this.search("list-approval");
    },
    handleSizeChange(val) {
      this.sizeChange("list-approval", val);
    },
    handleCurrentChange(val) {
      this.pageChange("list-approval", val);
    },
    async exportExcel(){
        if (this.hasPermissonCheck('kengen_flg')){
            let data = await this.exportApprovalFileCsv(this.queryParam);
            this.exportCsvCommon('SC015_',data)
        }else {
            this.not_permisson_popup = true
        }
    }
  }
};
</script>
<style scoped>
.ml-10 {
  margin-left: 10rem !important;
}
.action-form-search {
    padding-top: 45px;
}
</style>
